/**
 * 初始化方法
 * init
 * 入参：OBJ
 *  {
 *       popupType: '000': 点击右上角编辑按钮展示， '001': 点击置灰预定按钮展示
 *       evectionNo: string，出差单号， 必填
 *       evectionRequired： number， 是否出差单必填， 必填
 *       journeyId： number， 行程id， 必填， 无选择出差单时默认为0
 *   }
 *
 *
 *  @saveInfo 回调方法 点确定按钮触发
 *  返参： obj
 * {
 *   journeyId: string, 行程id
 *   evectionNo： string， 出差单号
 *   workTravelType: number, 因公出差的类型， 1是有选出差单，2是没有
 *   journeyType: number, 0 普通单， 1 不限行程单
 *   editType: string, 编辑类型，'000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
 *   travelCurrent： object, 当前选中的出差单， 如果没有选出差单则返回null
 * }
 * */
import ReservePopupBoxForId from "./components/reserve-popup-box-for-id/1.0.0/index.vue";
import ChooseTravelerForTravelPopup from './components/choose-traveler-for-travel-popup/1.0.0/index.vue'
// import CostAttributionList from '../../cost-attribution-list/1.0.0/index.vue'
import CostAttributionList from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/cost-attribution-list/index'
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail'
import consumer_evection_addOrUpdate from "@/lib/data-service/haolv-default/consumer_evection_addOrUpdate";
import applyButton from '@/lib/data-service/haolv-default/consumer_menu_applyButton'
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import refund_change_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get';
import consumer_web_staff_judgeOrderFree from '@/lib/data-service/haolv-default/consumer_web_staff_judgeOrderFree';
import consumer_apply_applyHotelJourney from '@/lib/data-service/haolv-default/consumer_apply_applyHotelJourney';
const awaitWrap = (promise) => {
    return promise
        .then(data => [null, data])
        .catch(err => [err, null])
};
const handlePromise = (list) => {
    return list.map(promise => {
        return promise.then(res => {
            return res
        }).catch(e => {
            return e
        })
    })
};
export default {
    data() {
        return {
            title: '',
            show: false,
            loading: false,
            popupType: '000',
            evectionNo: '',
            evectionRequired: 0,
            businessRuleControl: 1,
            allowChangeJourneyTime: 0, // 是否允许修改行程时间 0：否 1：是
            bookUpperBound: 6,
            isFreeApprove: 0,
            journeyId: 0,
            useTravel: '',
            travelCurrent: null, // 当前出差单信息
            evectionInfo: {},
            evectionParams: {
                journeyId: 0,
                reason: '', // 出差事由
                feeAffiliationType: 1,
                feeAffiliationName: '',
                feeAffiliationId: '',
                partnerEctionId: ''
            },
            travelUserList: [], // 当前选中的出行人

            jurisdiction: false, // 按钮权限范围
            userInfo: {},
            audit: false, // 是否显示办公

            userCanApprove: 1, // 0-不可以预订 1 可以预订

            businessInfo: {}, // 当前传入的业务城市时间

            shareEvectionNo: '',
            shareJourneyId: 0,
        }
    },
    props: {
        businessType: {
            type: Number,
            default: 1, // 机票：1， 火车票：2， 酒店：3
        },
    },
    components: {ReservePopupBoxForId, ChooseTravelerForTravelPopup, CostAttributionList},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        travelUserList(newVal, oldVal) {
            //console.log('travelUserList', newVal, oldVal)
        },
    },
    computed: {},
    filters: {},
    methods: {
        async init(params) {
            console.log('travel-popup', params);
            this.popupType = params.popupType;
            this.evectionNo = params.evectionNo;
            this.evectionRequired = params.evectionRequired;
            this.businessRuleControl = params.businessRuleControl;
            //this.journeyId = params.journeyId
            if (this.businessType === 1) {
                this.businessInfo = params.flightInfo || {}
            } else if (this.businessType === 2) {
                this.businessInfo = params.trainInfo || {}
            } else {
                this.businessInfo = params.hotelInfo || {}
                this.shareEvectionNo = this.businessInfo.shareEvectionNo ? this.businessInfo.shareEvectionNo : '';
                this.shareJourneyId = this.businessInfo.shareJourneyId ? this.businessInfo.shareJourneyId : '';
            }
            this.show = true
            this.loading = true
            let [policyErr, policyRes] = await awaitWrap(refund_change_policy_get());
            if (policyErr) {
                this.loading = false;
            }
            let [jurisdictionErr, jurisdictionRes] = await awaitWrap(applyButton());
            if (!jurisdictionErr) {
                this.jurisdiction = jurisdictionRes.datas.jurisdiction
            }
            let userRes = await get_user_info();
            this.userInfo = userRes.datas;
            this.audit = userRes.datas.audit;
            this.evectionRequired = policyRes.datas.evectionRequired;
            this.businessRuleControl = policyRes.datas.businessRuleControl;
            this.allowChangeJourneyTime = policyRes.datas.allowChangeJourneyTime;
            this.bookUpperBound = policyRes.datas.bookUpperBound;
            this.isFreeApprove = policyRes.datas.isFreeApprove;
            consumer_journey_getEvectionDetail({
                evectionNo: this.evectionNo,
                pageType: 1,
                personType: 2
            }).then(res => {
                this.journeyId = params.journeyId;
                this.evectionInfo = JSON.parse(JSON.stringify(res.datas))
                let evectionParamsTravellerList = [] // 防止返回的travellerList是[null]
                let shareList = [];
                let shareIdList = [];
                if (res.datas.travellerList) {
                    res.datas.travellerList.forEach(value => {
                        if (value && value.shareWith === 2) {
                            evectionParamsTravellerList.push(value.userId)
                        }
                        if (value && value.shareWith === 1) {
                            shareList.push(value);
                            shareIdList.push(value.userId)
                        }
                    })
                }
                this.evectionParams = {
                    journeyId: this.journeyId,
                    evectionNo: res.datas.evectionNo,
                    evectionType: res.datas.evectionType,
                    explainDesc: res.datas.explainDesc,
                    feeAffiliationId: res.datas.feeAffiliationId,
                    feeAffiliationName: res.datas.feeAffiliationName, // 费用归属名称
                    feeAffiliationType: res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1,
                    linkman: res.datas.linkman ? res.datas.linkman : '',
                    linkmanPhone: res.datas.linkmanPhone ? res.datas.linkmanPhone : '',
                    reason: res.datas.reason || '',
                    travelUserList: res.datas.travellerList ? evectionParamsTravellerList : [],
                    smsType: res.datas.contactsList && res.datas.contactsList.length > 0 ? res.datas.contactsList[0].smsType : 1, // 是否发送短信， 1为是， 0为否
                    travelUser: [],
                    partnerEctionId: res.datas.partnerEctionId,
                };
                this.travelUserList = JSON.parse(JSON.stringify(res.datas.travellerList));
                if (this.journeyId === 0) {
                    const params = {
                        travelCurrent: null,
                        isSetTravelList: true,
                        travelUserList: res.datas.travellerList
                    };
                    this.$nextTick(() => {
                        this.$refs['traveler'].init(params)
                    })
                }

                if (this.popupType === '000' || this.businessRuleControl === 2) {
                    this.$nextTick(() => {
                        const costAttributionList = this.$refs.costAttributionList;
                        if (costAttributionList) {
                            costAttributionList.init({type: '002'})
                        }
                    })
                }

                if (this.journeyId !== 0) {
                    this.$nextTick(()=>{
                        this.$refs['reservePopupBox'].setJourneyId({journeyId: this.journeyId}).then(currentEvection=>{
                            this.travelCurrent = currentEvection;
                            const params = {
                                travelCurrent: currentEvection,
                                isSetTravelList: true,
                                travelUserList: res.datas.travellerList
                            };
                            this.$nextTick(() => {
                                this.$refs['traveler'].init(params)
                            });

                            // 获取合住人
                            console.log(this.businessType, this.businessInfo, this.travelCurrent, shareList)
                            if (this.businessType === 3 && this.businessInfo.shareEvectionNo && this.travelCurrent && shareList.length > 0) {
// 调接口
                                console.log('进入合住');
                                consumer_apply_applyHotelJourney({
                                    "departTime": this.travelCurrent.active.departTime,
                                    "returnTime": this.travelCurrent.active.returnTime,
                                    "toCityId": this.travelCurrent.active.toCityId,
                                    "userName": shareList[0].staffName,
                                }).then(res=>{
                                    console.log('进入合住2');
                                    let data = res.datas;
                                    let shareCurrent = null;
                                    let allShareUserList = [];
                                    let otherUserList = [];
                                    let otherUserIdList = shareIdList;
                                    console.log(data);
                                    data.forEach(value=>{
                                        console.log(value)
                                        value.journeys.forEach(value1 => {
                                            console.log(value1);
                                            value.active = value1;
                                            console.log('333');
                                            console.log(this.businessInfo, this.shareEvectionNo,this.shareJourneyId,  value.evectionNo, value.active.id)
                                            if (this.businessInfo.shareEvectionNo === value.evectionNo && parseInt(this.businessInfo.shareJourneyId) === value.active.id) {
                                                shareCurrent = value;
                                                allShareUserList = value.active.staffList;
                                            }
                                        })
                                    })
                                    console.log('进入合住', allShareUserList, shareCurrent);
                                    allShareUserList.forEach(value => {
                                        if (shareIdList.indexOf(value.userId) > -1) {
                                            otherUserList.push(value)
                                        }
                                    })
                                    console.log('进入合住', otherUserList);
                                    this.$refs.traveler.setShareRoomUser({
                                        shareTravelCurrent: shareCurrent,
                                        allSelectOtherUserLit: allShareUserList,
                                        otherUserList: otherUserList,
                                        otherUserIdList: otherUserIdList,
                                    });
                                }).catch(e=>{
                                })
                            }
                            this.loading = false;
                        })
                    })
                } else {
                    this.loading = false;
                }
                // this.$refs['traveler'].initNoEvectionNoForUserList({
                //     travelUserList: this.travelUserList
                // })




                this.checkUserCanApprove();
            }).catch(e=>{
                this.loading = false
            }).finally(() => {
                // this.loading = false
            })
        },
        _clickText(){
            this.$refs.traveler.$refs.aCommonTravelerSelector.show();
        },
        _openEvectionTravelPopup() {
            this.$refs['reservePopupBox'].chooseTravel()
        },
        _handleBack() {
            this.show = false
        },
        _closeCallBack() {
            this.evectionParams.journeyId = this.journeyId
            this._handleBack()
        },
        _getCurrentEvection(val) {
            console.log('chufaxuanzhong1:', val)
            this.travelCurrent = val
            if (val) {
                this.evectionParams.journeyId = val.active.id;
                this.evectionParams.reason = val.evectionReason;
                this.evectionParams.feeAffiliationName = val.feeAffiliation;
                this.evectionParams.feeAffiliationType = val.feeAffiliationType;
                this.evectionParams.feeAffiliationId = val.feeAffiliationId;
            } else {
                // this.evectionParams.journeyId = 0
                // this.evectionParams.reason = this.evectionInfo.reason
                // this.evectionParams.feeAffiliationName = this.evectionInfo.feeAffiliationName
                // this.evectionParams.feeAffiliationId = this.evectionInfo.feeAffiliationId
                // this.evectionParams.feeAffiliationType = this.evectionInfo.feeAffiliationType ? this.evectionInfo.feeAffiliationType : 1
                this.travelUserList = [];
                this.evectionParams.journeyId = 0;
                this.evectionParams.reason = '商务出行';
                this.evectionParams.feeAffiliationName = '各自所在的部门';
                this.evectionParams.feeAffiliationId = -1;
                this.evectionParams.feeAffiliationType = 1
            }

            // 当init之后第一次推送出来，当前journeyId是等于初始化时候的id的，获取完当前行程信息后，去显示当前init时候选中的出行人
            // const params = {
            //     travelCurrent: val,
            //     isSetTravelList: (this.evectionParams.journeyId === this.journeyId && this.journeyId !== 0),
            //     travelUserList: this.evectionParams.travelUserList
            // }
            // this.$refs['traveler'].init(params)
            // 当init之后第一次推送出来，当前journeyId是等于初始化时候的id的，获取完当前行程信息后，去显示当前init时候选中的出行人
            const isSetTravelList = (this.evectionParams.journeyId === this.journeyId && this.journeyId !== 0);
            if (isSetTravelList) {
                this.travelUserList = this.evectionInfo.travellerList
            }
            const params = {
                travelCurrent: val,
                isSetTravelList,
                travelUserList: this.travelUserList
            };
            this.$nextTick(() => {
                this.$refs['traveler'].init(params)
            })
        },
        _getSelectedStaffIdList(val) {
            this.evectionParams.travelUserList = val.selectedStaffIdList;
            this.evectionParams.sharedList = val.otherUserIdList;
            this.shareEvectionNo = val['shareEvection'] ? val['shareEvection']['evectionNo'] : '';
            this.shareJourneyId = val['shareEvection'] ? val['shareEvection']['active']['id'] : 0;
        },
        _toApply() {
            this.$router.replace({
                name: 'admin-add-evection'
            })
        },
        async checkUserCanApprove() {
            let userParams = {
                'userIds': [],
            };
            this.evectionParams['travelUserList'].forEach((value) => {
                userParams['userIds'].push(value);
            });
            let userRes = await consumer_web_staff_judgeOrderFree(userParams);
            this.userCanApprove = userRes['datas'];
        },
        async _submit() {
            await this.checkUserCanApprove();
            if (this.popupType === '001' &&
                this.businessRuleControl === 1 &&
                this.jurisdiction &&
                this.evectionRequired === 1 &&
                this.isFreeApprove === 0 &&
                this.travelCurrent === null &&
                this.userCanApprove === 0) {
                this.$message.warning('请选择一个出差单');
                return;
            }

            let checkParams = {
                evectionRequired: this.evectionRequired,
                evectionType: 2,
                workTravelType: this.travelCurrent ? 1 : 2,
                journeyType: this.travelCurrent ? this.travelCurrent.active.journeyType : '',
            }
            let [err, res] = await awaitWrap(this.$refs.traveler.check(checkParams))
            if (err) {
                if (err.type === '001') {
                    this.$message.error(err.msg)
                }
                return
            }
            // 无出差单->无出差单
            // 无出差单->有出差单
            // 有出差单-> 无出差单
            // 有出差单-> 有出差单
            let evectionNo = this.travelCurrent ? this.travelCurrent.evectionNo : this.evectionNo
            if (this.evectionParams.journeyId === 0 && this.journeyId !== 0) {
                evectionNo = ''
            }
            let params = {
                journeyId: this.evectionParams.journeyId,
                applyId: this.travelCurrent ? this.travelCurrent.id : "",
                endDate: this.travelCurrent ? this.travelCurrent.endDate : "",
                evectionNo,
                evectionType: 2, // 1是个人出差，2是出差单出差
                startDate: this.travelCurrent ? this.travelCurrent.startDate : "",
                travellerList: this.evectionParams.travelUserList,
                personType: 2,
                cabin: '',

                reason: this.evectionParams.reason,
                feeAffiliationId: this.evectionParams.feeAffiliationId,
                feeAffiliationName: this.evectionParams.feeAffiliationName,
                feeAffiliationType: this.evectionParams.feeAffiliationType,
                partnerEctionId: this.evectionParams.partnerEctionId,
            };
            let [error, result] = await awaitWrap(consumer_evection_addOrUpdate(params))
            if (error) {
                return
            }

            // editType: '000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
            let editType = ''
            if (result.datas === this.evectionNo && this.evectionParams.journeyId === this.journeyId) {
                editType = '002'
            } else {
                if (this.evectionParams.journeyId === 0 && this.journeyId !== 0) {
                    editType = '001'
                } else {
                    editType = '000'
                }
            }
            this.journeyId = this.evectionParams.journeyId
            let resultParams = {
                journeyId: this.journeyId,
                evectionNo: result ? result.datas : this.evectionNo,
                workTravelType: this.travelCurrent ? 1 : 2, // 因公出差的类型， 1是有选出差单，2是没有
                journeyType: this.travelCurrent ? this.travelCurrent.active.journeyType : '', // 0 普通单， 1 不限行程单
                travelCurrent: this.travelCurrent,
                editType,
            }
            this.$emit('saveInfo', resultParams)
            this._handleBack()
        },
        //
        _getJourneyInfo() {
            // 获取出差信息
            let params = {
                "departTime": this.travelCurrent ? this.travelCurrent.active.departTime : this.businessInfo.checkInDate,
                "returnTime": this.travelCurrent ? this.travelCurrent.active.returnTime : this.businessInfo.checkOutDate,
                "toCityId": this.travelCurrent ? this.travelCurrent.active.toCityId : this.businessInfo.cityId,
            };
            this.$refs.traveler.setHotelJourneyToShareRoomAndShowPopup(params);
        },
    }
}
