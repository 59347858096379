import refund_change_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get';
import consumer_apply_checkLoginUserPolicy from '@/lib/data-service/haolv-default/consumer_apply_checkLoginUserPolicy'
import CommonTravelerSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/common-traveler-selector/1.0.0/index.vue';
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import ShareRoomUserSelectPopup from "@/component/share-room-user-select-popup/index.vue";

export default {
    data() {
        return {
            isSelectedOrder: false,//是否已选出差单
            isSelectedMyself: false,//是否只能选当前登陆用户为出行人
            isShowAllStaffPopup: false,//是否显示全体人员弹窗
            isShowEvectionStaffPopup: false,//是否显示出差单出行人弹窗
            evectionRequired: 0,//出差单是否必填
            selectedStaffMaxNo: 6,//预定人数上限
            userInfo: {},
            allUserItems: [],//全体员工弹窗数据
            allStaffList: [],//全体员工列表
            selectedStaffIdList: [],//已选的员工id列表
            selectedStaffList: [],//已选的员工列表
            allSelectedStaffList: [], // 可选员工列表

            isFreeApprove: 0, // 是否免审：1.是，0.否

            enableAutoInit: false,
            selectMode: '100',

            evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            travelCurrent: null,

            userError: false,

            travelUserList: [],

            otherUserList: [], // 合住人列表
            otherUserIdList: [], // 合住人id列表
            allSelectOtherUserLit: [], // 因公选出差单情况下可选的合住人合集列表
            shareTravelCurrent: null,
        }
    },
    props: {
        // 传入的出差单数据
        // travelCurrent: {
        //     type: Object,
        //     default: null
        // },
        useTravel: {
            type: [String, Number],
            default: ''
        },
        businessType: {
            type: [String, Number],
            default: ''
        }
    },
    components: {
        CommonTravelerSelector,
        ShareRoomUserSelectPopup,
    },
    mounted() {
        const __this = this;
        this.refundChangePolicyGet();
        this.checkOrder();
        __this.$refs.aCommonTravelerSelector.init({
            __this: __this,
            situation: '100',
            businessType: __this.businessType,
            select_mode: `multiple`,
            dialog: {
                title: `请选择出行人员`,
            },
            get_params() {
                const p = {
                    applyJourneyId: '',
                };
                return p;
            },
        });


        __this.$refs.aCommonTravelerSelector.$refs.aAvailableTravellerSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log(args);
            console.log('aAvailableTravellerSelector.btn_ok_click_event_handler')
            let travelUserList = args.selected_entity_list
            travelUserList.forEach((value, index) => {
                if (!value.userId) {
                    value.userId = value.id
                }
            })
            this.selectedStaffList = travelUserList
            this.selectedStaffIdList = args.selected_id_list;
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList);
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
                'shareRoomEvection': this.shareTravelCurrent,
            })
        };
        __this.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log(args);
            console.log('aTravelerMultipleSelector.btn_ok_click_event_handler')
            let travelUserList = args.selected_entity_list
            let staffList = []
            let staffIdList = []
            travelUserList.forEach((value, index) => {
                if (!value.userId) {
                    value.userId = value.id
                }
                let maxNo = 6;
                if (this.selectedStaffMaxNo === 1 && this.isFreeApprove === 1) {
                    maxNo = 6
                } else {
                    maxNo = this.selectedStaffMaxNo
                }
                if (index < maxNo) {
                    staffList.push(value)
                    staffIdList.push(value.userId)
                }
            })
            this.selectedStaffList = staffList
            this.selectedStaffIdList = staffIdList
            console.log(this.selectedStaffIdList)
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList);
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
                'shareRoomEvection': this.shareTravelCurrent,
            })
        };

        __this.$refs.aCommonTravelerSelector.$refs.aAvailableTravellerSelector.init_parameter.btn_cancel_click_event_handler = () => {
            console.log('aAvailableTravellerSelector.btn_cancel_click_event_handler')
        };
        __this.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.init_parameter.btn_cancel_click_event_handler = () => {
            console.log('aCommonTravelerSelector.btn_cancel_click_event_handler')
        };
    },
    computed: {
        canChangeFactor() {
            let isOk = true;
            if(this.useTravel === 1 && this.evectionRequired === 1) {
                isOk = false;
            }
            if (this.travelCurrent !== null) {
                isOk = false
            }
            return isOk
        },
        canAddUser() {
            if (this.travelCurrent === null) {
                // 如果不是免审，则走正常
                if (this.isFreeApprove === 0) {
                    return this.travelUserList.length < this.selectedStaffMaxNo
                } else {
                    // 如果是免审，则不受帮订人数限制
                    return this.travelUserList.length < 6
                }
            } else {
                return true
            }
        },
        canDelUser() {
            if (this.travelCurrent === null) {
                return (this.selectedStaffMaxNo > 1 || (this.selectedStaffMaxNo === 1 && this.isFreeApprove === 1))
            } else {
                return true
            }
        },
    },
    watch: {},
    methods: {
        init(params) {
            console.log('i', params);
            this.travelCurrent = JSON.parse(JSON.stringify(params.travelCurrent))
            this.checkOrder();
            if (params.travelCurrent === null) {
                this.selectedStaffList = [];
                this.selectedStaffIdList = [];
                this.travelUserList = [];
                //this.isChangeFactor = true
                this.applyJourneyId = 0;
                if (this.useTravel === 1 && this.selectedStaffMaxNo === 1 && this.isFreeApprove === 0) {
                    this.selectedStaffList = [{
                        userId: this.$store.state.userInfo.id,
                        staffName: this.$store.state.userInfo.realName
                    }];
                    this.selectedStaffIdList = [this.userInfo.id]
                } else {
                    let selectedStaffList = [];
                    let selectedStaffIdList = [];
                    params.travelUserList.forEach(value=>{
                        if (value) {
                            if (value.shareWith === 2) {
                                selectedStaffList.push(value);
                                selectedStaffIdList.push(value.userId);
                            } else {

                            }
                        }
                    })
                    this.selectedStaffList = selectedStaffList
                    this.selectedStaffIdList = selectedStaffIdList
                }
                this.$emit('getSelectedStaffIdList', this.selectedStaffIdList);
                this.$emit('getSelectedStaffIdListForHotel', {
                    'selectedStaffIdList': this.selectedStaffIdList,
                    'otherUserIdList': this.otherUserIdList,
                    'shareRoomEvection': this.shareTravelCurrent,
                })
            } else {
                let resultStaffList = [];
                let [...staffList] = params.travelCurrent.active.staffList;
                // 因公选出差单的情况，不受帮订人数限制
                resultStaffList = staffList.filter(value => value.bookStatus === 1)
                this.allSelectedStaffList = params.travelCurrent.active.staffList;
                this.selectedStaffList = resultStaffList;
                if (params.isSetTravelList) {
                    let selectedStaffList = [];
                    let selectedStaffIdList = [];
                    params.travelUserList.forEach(value=>{
                        if (value) {
                            if (value.shareWith === 2) {
                                selectedStaffList.push(value);
                                selectedStaffIdList.push(value.userId);
                            } else {

                            }
                        }
                    })
                    this.selectedStaffIdList = selectedStaffIdList;
                } else {
                    this.selectedStaffIdList = [...new Set(this.selectedStaffList.map(item => item.userId))];
                }
                this.$emit('getSelectedStaffIdList', this.selectedStaffIdList);
                this.$emit('getSelectedStaffIdListForHotel', {
                    'selectedStaffIdList': this.selectedStaffIdList,
                    'otherUserIdList': this.otherUserIdList,
                    'shareRoomEvection': this.shareTravelCurrent,
                })
                this.$refs.aCommonTravelerSelector.init({
                    __this: this,
                    situation: '100',
                    businessType: this.businessType,
                    dialog: {
                        title: `请选择出行人员`,
                    },
                    get_params() {
                        const p = {
                            applyJourneyId: params.travelCurrent.active.id,
                        };
                        return p;
                    },
                });
            }
        },
        check(params) {
            return new Promise(((resolve, reject) => {
                this.evectionDetailType = '';
                // 因公出差带普通出差单
                if (parseInt(params.evectionType) === 2 && parseInt(params.workTravelType) === 1 && (parseInt(params.journeyType) === 0 || params.journeyType === undefined)) {
                    this.evectionDetailType = '001'
                }
                // 因公出差不带出差单
                if (parseInt(params.evectionType) === 2 && parseInt(params.workTravelType) === 2) {
                    this.evectionDetailType = '002'
                }
                // 因公出差带不限出差单
                if (parseInt(params.evectionType) === 2 && parseInt(params.workTravelType) === 1 && parseInt(params.journeyType) === 1) {
                    this.evectionDetailType = '003'
                }
                // 因私出差
                if (parseInt(params.evectionType) === 1) {
                    this.evectionDetailType = '101'
                }
                // 如果必传出差单，缺选了出差单
                if (params.evectionRequired === 1 && this.travelCurrent !== null && this.selectedStaffIdList.length === 0) {
                    reject({type: '001', msg: '出行人不能为空'});
                    return
                }
                if (this.travelCurrent !== null && this.selectedStaffIdList.length === 0) {
                    reject({type: '001', msg: '未选择出行人'});
                    return
                }
                if (this.evectionDetailType === '002') {
                    let staffList = [];
                    this.selectedStaffList.forEach((value) => {
                        staffList.push(value.userId);
                    });
                    let checkParams = {
                        businessType: this.businessType,
                        userIds: staffList
                    };
                    if (staffList.length === 0) {
                        resolve({type: '001'})
                        return;
                    }
                    consumer_apply_checkLoginUserPolicy(checkParams).then(res => {
                        resolve({type: '001'})
                    }, error => {
                        reject({type: '002', msg: error.datas.msg})
                    }).catch(err => {
                        reject({type: '002', msg: err.datas.msg})
                    })
                } else {
                    resolve('001')
                }
            }))
        },
        delTrainUser(val, index) {
            this.selectedStaffList.splice(index, 1)
            let staffIdList = []
            this.selectedStaffList.forEach(value => {
                staffIdList.push(value.userId)
            })
            this.selectedStaffIdList = staffIdList
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList);
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
                'shareRoomEvection': this.shareTravelCurrent,
            })
        },
        // 有预设的弹出选择出差人框
        showTravelSelectUserPopup() {
            let activeUserIdList = []
            this.selectedStaffList.forEach(value => {
                activeUserIdList.push(value.userId)
            })
            this.selectedStaffIdList = activeUserIdList
            this.$refs['aCommonTravelerSelector'].show()
        },
        // 没有预设弹出框选择员工
        showSelectUserPopup() {
            if(this.selectedStaffMaxNo > 1 || this.isFreeApprove === 1){
                let currentActiveList = []
                this.selectedStaffList.forEach(value => {
                    currentActiveList.push(value.userId)
                })
                this.selectedStaffIdList = currentActiveList
                this.$refs.aCommonTravelerSelector.show();
            } else {
                if(this.selectedStaffMaxNo === 1){
                    this.$message({
                        type: 'warning',
                        message: '只能选自己为出行人'
                    })
                } else {
                    let currentActiveList = []
                    this.selectedStaffList.forEach(value => {
                        currentActiveList.push(value.userId)
                    })
                    this.selectedStaffIdList = currentActiveList
                    this.$refs.aCommonTravelerSelector.show();
                }
            }
            /*if(this.evectionRequired === 0 && (this.selectedStaffMaxNo > 1 || this.isFreeApprove === 1)){
                let currentActiveList = []
                this.selectedStaffList.forEach(value => {
                    currentActiveList.push(value.userId)
                })
                this.selectedStaffIdList = currentActiveList
                this.$refs.aCommonTravelerSelector.show();
            } else {
                if(this.evectionRequired === 0 && this.selectedStaffMaxNo === 1 && this.isFreeApprove === 0){
                    this.$message({
                        type: 'warning',
                        message: '只能选自己为出行人'
                    })
                } else {
                    if(this.evectionRequired === 1 && this.travelCurrent === null){
                        this.$message({
                            type: "warning",
                            message: '请选择出差单'
                        })
                    }else {
                        this.$message({
                            type: "warning",
                            message: '不能修改出差单既定行程'
                        })
                    }
                }
            }*/
        },
        setShareRoomUser(params) {
            this.shareTravelCurrent = params.shareTravelCurrent;
            this.allSelectOtherUserLit = params.allSelectOtherUserLit;
            this.otherUserList = params.otherUserList;
            this.otherUserIdList = params.otherUserIdList;
        },
        _toggleOther(item) {
            if (item.bookStatus === 2) {
                return
            }
            let indexNum = this.otherUserIdList.indexOf(item.userId);
            if (indexNum > -1) {
                // 已有，取消
                this.otherUserIdList.splice(indexNum, 1)
            } else {
                // 没有，添加
                if (this.selectedStaffIdList.length > 1) {
                    return;
                }
                if (this.otherUserIdList.length > 0) {
                    return;
                }
                this.otherUserIdList.push(item.userId)
            }
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
                'shareRoomEvection': this.shareTravelCurrent,
            })
        },
        // 获取出差规则
        refundChangePolicyGet() {
            refund_change_policy_get().then(res => {
                this.evectionRequired = res.datas.evectionRequired;
                this.selectedStaffMaxNo = res.datas.bookUpperBound;
                this.isFreeApprove = res.datas.isFreeApprove || 0;
                this.checkMyself()
            })
        },
        //判断是否已选出差单
        checkOrder() {
            if (this.travelCurrent === null || this.travelCurrent === []) {
                this.isSelectedOrder = false
            } else {
                this.isSelectedOrder = true
            }
        },
        //检查是否默认选中自己
        async checkMyself() {
            if (this.evectionRequired === 0 && this.selectedStaffMaxNo === 1 && this.isFreeApprove === 0) {
                this.isSelectedMyself = true;
                await this.getUserInfo();
                this.selectedStaffList = [{
                    userId: this.userInfo.id,
                    staffName: this.userInfo.realName
                }];
                this.selectedStaffIdList = [this.userInfo.id];
                this.$emit('getSelectedStaffIdList', this.selectedStaffIdList);
                this.$emit('getSelectedStaffIdListForHotel', {
                    'selectedStaffIdList': this.selectedStaffIdList,
                    'otherUserIdList': this.otherUserIdList,
                    'shareRoomEvection': this.shareTravelCurrent,
                })
            }
        },
        async getUserInfo() {
            let res = await get_user_info();
            this.userInfo = res.datas
        },
        async showStaffPopup() {
            if (!this.isSelectedOrder) {
                if (this.evectionRequired === 0) {
                    if (this.selectedStaffMaxNo === 1 && this.isFreeApprove === 0) {
                        this.$Toast('只能选自己为出行人');
                        return
                    }
                }
                this.$refs.aCommonTravelerSelector.show()

            } else {
                this.$refs.aCommonTravelerSelector.show()
            }
        },
        toggle(item) {
            if (item.bookStatus === 2) {
                return
            }
            let indexNum = this.selectedStaffIdList.indexOf(item.userId);
            if (indexNum > -1) {
                // 已有，取消
                this.selectedStaffIdList.splice(indexNum, 1)
            } else {
                // 没有，添加
                if (this.otherUserIdList.length > 0 && this.selectedStaffIdList.length > 0) {
                    return;
                }
                this.selectedStaffIdList.push(item.userId)
            }
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList);
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
                'shareRoomEvection': this.shareTravelCurrent,
            })
        },
        hide() {
            const __this = this;
            return __this.$refs.aCommonTravelerSelector.hide();
        },
        get_show() {
            const __this = this;
            return __this.$refs.aCommonTravelerSelector.get_show();
        },

        // 设置因公没选出差单回显的出行人
        initNoEvectionNoForUserList(params) {
            this.selectedStaffList = params.travelUserList
            this.selectedStaffIdList = this.selectedStaffList.map(val => val.userId)
        },

        _showShareRoomUserSelectPopup() {
            if (this.selectedStaffIdList.length > 1) {
                return;
            }
            // 向父组件请求出差单信息
            this.$emit('getJourneyInfo');

        },

        setHotelJourneyToShareRoomAndShowPopup(params) {
            this.$refs.aShareRoomUserSelectPopup.init(params);
            this.$refs.aShareRoomUserSelectPopup.show();
        },
    }
}
